import React from "react"
import FeaturedImage from "./FeaturedImage"
import PageHeader from "./PageHeader"
import { getLongDate } from "../../utils/getLongDate"
import Link from "./Link"

export default function SinglePost({ data }) {
  const { title, content, featuredImage, date, metadataArtikel } = data.wpPost
  const newsDate = getLongDate(date, "Do MMMM, YYYY")
  return (
    <div className="">
      <div className="small-container">
        <div className="text-3xl">
          <Link to="/blogg">&larr;</Link>
        </div>
        <header className="space-y-4">
          <PageHeader text={title} />
          <div className="flex flex-col text-base ">
            <div className="">{newsDate}</div>
            {metadataArtikel.publikationArtikel && (
              <div className="">{metadataArtikel.publikationArtikel}</div>
            )}
          </div>
        </header>
        <div> {featuredImage && <FeaturedImage image={featuredImage} />}</div>
        <div className="">
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>{" "}
      </div>
      <div className="hidden">
        <blockquote></blockquote>
      </div>
    </div>
  )
}
